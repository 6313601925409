



























































import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      rules: {
        name: [{ required: true, message: '请填写商品名称' }],
        code: [{ required: true, message: '请填写商品编码' }],
        price: [{ required: false, message: '请填写价格保护' }],
        sku: [{ required: false, message: '请填写商品id' }],
        region: [{ required: true, message: '请填写地区' }],
        enable: [{ required: true, message: '请填写启用' }],
        remarks: [{ required: false, message: '请填写商品备注' }],
      },
      model: {
        name: '',
        code: '',
        sku: null,
        price: '',
        region: '',
        enable: '',
        cart: '',
        remarks: '',
      },
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      this.formLoading = true
      const { data: configs } = await this.$http.get(`goods/${this.id}`)
      this.model = Object.assign(this.model, configs)
      this.formLoading = false
    },
    async save() {
      ~(this.$refs.form as any).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true
          if (this.id) {
            await this.$http.put(`goods/${this.id}`, this.model)
          } else {
            await this.$http.post('goods', this.model)
          }
          this.loading = false
          this.$router.push('/good/list')
          this.$message.success('保存成功')
        }
      })
    },
  },
})
